<template>
  <filter-location-block
    module-name="campaignPlanning"
    :use-label="false"
    :show-audience="false"
    :show-tooltips="true"
    :only-cities="true"
    :allow-multiple="false"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
    @update="handleUpdate"
    @remove="handleRemove"
  />
</template>

<script>
const FilterLocationBlock = () => import(/* webpackChunkName: "filter-location-campaign-planning" */ "@/blocks/common/filters/FilterLocation.vue")

export default {
  name: "FilterLocation",

  components: {
    FilterLocationBlock
  },

  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters['campaignPlanning/findFilterByType']("location"))
    },
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch('campaignPlanning/addFilter', data)
    },

    handleUpdate(data) {
      this.$store.dispatch('campaignPlanning/updateFilter', data)
    },

    handleRemove(data) {
      this.$store.dispatch('campaignPlanning/removeFilter', data.id)
    }
  }
}
</script>
